<template>
  <div class="bindPhone">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
        <span>返回</span>
      </div>
      <div class="title">绑定手机</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <!-- 手机号 -->
      <div class="phoneInputBox">
        <van-dropdown-menu class="areaBox">
          <van-dropdown-item :title="areaCode ? areaCode : '区号'" v-model="areaCode" :options="areaCodes" />
        </van-dropdown-menu>
        <van-field class="vanInput phoneInput" center v-model="phoneVal" :border="false" placeholder="请输入手机号" />
      </div>
      <!-- 验证码 -->
      <van-field class="vanInput codeInput" v-model="codeVal" :border="false" placeholder="请输入验证码">
        <template #button>
          <div class="loading" v-if="loading">
            <van-loading type="spinner" color="#ec8737" size="20" />
          </div>
          <div class="sendCode" v-else-if="timer">{{ timer }}S</div>
          <div class="sendCode" v-else @click="sendCode">获取验证码</div>
        </template>
      </van-field>
      <!-- 绑定按钮 -->
      <div class="bindBtn" @click="mobileBind">确认</div>
    </div>
  </div>
</template>

<script>
import { AreaCode } from '@/utils/areaCode.js';
import { sendCode, mobileBind } from '@/api/user';
import { Field, Loading, DropdownMenu, DropdownItem, Toast } from 'vant';
export default {
  name: 'BindPhone',
  components: {
    [Field.name]: Field,
    [Loading.name]: Loading,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },
  data() {
    return {
      phoneVal: '', // 手机号
      codeVal: '', // 验证码
      areaCode: '+86', // 选中区号
      areaCodes: AreaCode, // 区号列表
      timer: 0, // 倒计时时间
      loading: false, // loading
      hasPhone: '',
    };
  },
  created() {
    this.hasPhone = this.$store.getters.userInfo.mobile;
  },
  methods: {
    // 倒计时
    countdown() {
      this.timer = 60;
      let countdown = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          clearInterval(countdown);
        }
      }, 1000);
    },
    // 发送验证码
    async sendCode() {
      if (!this.areaCode) {
        Toast('请选择区号');
        return;
      }
      if (!this.phoneVal) {
        Toast('请输入手机号');
        return;
      }
      this.loading = true;
      let req = {
        mobile: this.areaCode + this.phoneVal,
        type: 1, // 1-绑定手机号 2-手机号登陆
      };
      let ret = await this.$Api(sendCode, req);
      this.loading = false;
      if (ret && ret.code == 200) {
        this.countdown();
        Toast('发送成功');
      } else {
        Toast(ret.tip || '验证码发送失败');
      }
    },
    // 绑定换绑手机号
    async mobileBind() {
      if (!this.areaCode) {
        Toast('请选择区号');
        return;
      }
      if (!this.phoneVal) {
        Toast('请输入手机号');
        return;
      }
      if (!this.codeVal) {
        Toast('请输入验证码');
        return;
      }
      let req = {
        mobile: this.areaCode + this.phoneVal,
        code: this.codeVal,
      };
      this.$store.commit('app/SET_LOADING', true);
      try {
        let ret = await this.$Api(mobileBind, req);
        this.$store.commit('app/SET_LOADING', false);
        if (ret && ret.code == 200) {
          Toast('绑定成功');
          this.$router.push({
            path: '/bindSuccess',
            query: { phone: req.mobile },
          });
        } else {
          Toast(ret.tip || '绑定失败，请稍后再试');
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('操作失败');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bindPhone {
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }

      span {
        font-size: 18px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }
  .main {
    padding: 0 16px;
    margin-top: 50px;
    .phoneInputBox {
      height: 44px;
      border-radius: 4px;
      margin-bottom: 20px;
      overflow: hidden;
      display: flex;
      .areaBox {
        width: 100px;
        height: 44px;

        /deep/ .van-dropdown-menu__bar {
          height: 100%;
          background: #fff;
        }

        /deep/ .van-ellipsis {
          color: black;
        }

        /deep/ .van-dropdown-menu__title {
          margin-left: 10px;
          font-size: 14px;
        }
      }
    }
  }

  .vanInput {
    height: 44px;
    background: #fff;

    /deep/ .van-field__control {
      color: rgb(153, 153, 153);
      font-size: 16px;
    }

    ::placeholder {
      color: rgb(153, 153, 153);
      font-size: 16px;
    }
  }

  .codeInput {
    border-radius: 4px;
    padding-left: 30px;
    .sendCode {
      width: 100px;
      line-height: 15px;
      height: 15px;
      text-align: center;
      font-size: 16px;
      color: rgb(255, 103, 143);
      border-left: solid 1px rgba(51, 51, 51, 0.14);
    }

    .loading {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .bindBtn {
    height: 44px;
    line-height: 44px;
    border-radius: 4px;
    margin: 56px 10px 0;
    font-size: 14px;
    text-align: center;
    color: #fff;
    background-color: rgb(255, 103, 143);
  }
}
</style>
